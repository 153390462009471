// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@5.2.7_webpack@5.97.1/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@5.2.7_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../common/temp/node_modules/.pnpm/css-loader@5.2.7_webpack@5.97.1/node_modules/css-loader/dist/cjs.js!../../../../common/temp/node_modules/.pnpm/plyr@3.7.8/node_modules/plyr/dist/plyr.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "video.svelte-1l99lpe{border-radius:inherit;-o-object-fit:contain;object-fit:contain}video.svelte-1l99lpe::-webkit-media-controls{visibility:hidden}video.svelte-1l99lpe::-webkit-media-controls-enclosure{visibility:visible}\n/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJmaWxlIjoiSGxzVmlkZW8uc3ZlbHRlIiwibWFwcGluZ3MiOiJBQXdLVSw2QkFaUixxQkFDRSxzQkFDQSx3Q0FDSixDQUVFLDZDQUNFLGlCQUNKLENBRUUsdURBQ0Usa0JBQ0oiLCJuYW1lcyI6W10sInNvdXJjZXMiOlsiSGxzVmlkZW8uc3ZlbHRlIl19*/", "",{"version":3,"sources":["webpack://./../../packages/ui/src/components/HlsVideo.svelte"],"names":[],"mappings":"AA4JE,qBACE,qBAAA,CACA,qBAAA,CAAA,kBACJ,CAEE,6CACE,iBACJ,CAEE,uDACE,kBACJ;AAtKA,iRAAiR","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
